import { createRouter, createWebHistory, } from "vue-router";
const routes = [
    {
        path: "/",
        name: "LAYOUT",
        redirect: "/home",
        component: () => import(/* webpackChunkName: "layout" */ "../views/layout/index.vue"),
        children: [
            {
                path: "home",
                name: "HOME",
                component: () => import(/* webpackChunkName: "about" */ "../views/home/Home.vue"),
            },
            {
                path: "/deposit",
                name: "Deposit",
                component: () => import("../views/deposit/index.vue"),
            },
            {
                path: "/accountList",
                name: "accountList",
                component: () => import("../views/tool/addAccount/index.vue"),
            },
            {
                path: "/my",
                name: "my",
                component: () => import("../views/my/my.vue"),
            },
            {
                path: "/team",
                name: "team",
                component: () => import("../views/team/index.vue"),
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login/login.vue"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../views/login/register.vue"),
    },
    {
        path: "/forgetPassword",
        name: "forgetPsw",
        component: () => import("../views/login/forgetPsw.vue"),
    },
    {
        path: "/home/tutoriallist",
        name: "tutoriallist",
        component: () => import("../views/home/tutoriallist/index.vue"),
    },
    {
        path: "/home/newTutoria",
        name: "newTutoria",
        component: () => import("../views/home/newtutor/index.vue"),
    },
    {
        path: "/home/newTutoria/details",
        name: "newTutorialDetails",
        component: () => import("../views/home/newtutor/newTutorDetails.vue"),
        props: true,
    },
    {
        path: "/my/userInfo",
        name: "userInfo",
        component: () => import("../views/my/userInfo/userInfo.vue"),
    },
    {
        path: "/my/quotaHistory",
        name: "quotaHistory",
        component: () => import("../views/my/quotaHistory/index.vue"),
    },
    {
        path: "/my/buyHistory",
        name: "buyHistory",
        component: () => import("../views/my/depositHistory/index.vue"),
    },
    {
        path: "/my/server",
        name: "server",
        component: () => import("../views/my/customerService/index.vue"),
    },
    {
        path: "/my/message",
        name: "message",
        component: () => import("../views/my/message/index.vue"),
    },
    {
        path: "/my/newbie",
        name: "NewbieRewards",
        component: () => import("../views/my/newbieRewards/index.vue"),
    },
    {
        path: "/my/pincode",
        name: "pinCode",
        component: () => import("../views/my/pinCode/index.vue"),
    },
    {
        path: "/my/SellHistory",
        name: "SellHistory",
        component: () => import("../views/my/SellHistory/index.vue"),
    },
    {
        path: "/accountList/selecttool",
        name: "selecttool",
        component: () => import("../views/tool/selecttool/selectTool.vue"),
    },
    {
        path: "/accountList/selecttool/mobikwik",
        name: "mobikwik",
        component: () => import("../views/tool/mobikwik/mobikwik.vue"),
    },
    {
        path: "/accountList/selecttool/airtel",
        name: "airtel",
        component: () => import("../views/tool/airtel/airtel.vue"),
    },
    {
        path: "/accountList/selecttool/freecharge",
        name: "freecharge",
        component: () => import("../views/tool/freecharge/index.vue"),
    },
    {
        path: "/tool/accountdetails",
        name: "accountDetails",
        component: () => import("../views/tool/accountDetails/index.vue"),
    },
    {
        path: "/tool",
        name: "tool",
        component: () => import("../views/tool/index.vue"),
    },
    {
        path: "/accountList/addAccount",
        name: "add",
        component: () => import("../views/tool/addAccount/addAccount.vue"),
    },
    {
        path: "/accountList/editAccount",
        name: "editAccount",
        component: () => import("../views/tool/addAccount/editAccount.vue"),
    },
    {
        path: "/accountList/addUPI",
        name: "addUPI",
        component: () => import("../views/tool/addAccount/addupi.vue"),
    },
    {
        path: "/team/timeView",
        name: "timeView",
        component: () => import("../views/team/TimeView/index.vue"),
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
function RouterStack(router) {
    router.afterEach((to, from) => {
        // console.log(to, from); // 仅保留路由跳转日志
    });
    return router;
}
RouterStack(router);
export default router;
